@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/utilities";

$progress-bg: rgba(108, 117, 125, 0.419);
$gradient: linear-gradient(90deg, rgb(219, 230, 236) 25%, rgb(208, 234, 241) 65%, rgb(164, 188, 194) 85%, rgb(129, 168, 184) 95%);
$link-color: #35ac92;

@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "../../node_modules/bootstrap/scss/root";

#ContainerApp {
    overflow-x: hidden;
}

.lineaFooter {
    height: 250px;
}

p section {
    font-size: 1.5em;
}

.navItems:hover {
    transform: scale(1.2);
}

a {
    text-decoration: none;
}

.shadowRef {
    -webkit-box-shadow: 0px 0px 14px 5px #000000;
    box-shadow: 0px 0px 14px 5px #000000;
}

ul li {
    list-style: none;
    margin-bottom: 2rem;
}

.barraProgreso {
    width: 75%;
    height: 25px;
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 400px;
    position: relative;
    height: 0;
}

.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}